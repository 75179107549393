import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAssetTypes(ctx, params) {
      return useJwt.getAssetTypes(params).then(response => response);
    },
    getAssetTypeDetail(ctx, id) {
      return useJwt.getAssetTypeDetail(id).then(response => response);
    },
    createAssetType(ctx, data) {
      return useJwt.createAssetType(data).then(response => response);
    },
    updateAssetType(ctx, data) {
      return useJwt.updateAssetType(data).then(response => response);
    },
    deleteAssetTypes(ctx, data) {
      return useJwt.deleteAssetTypes(data).then(response => response);
    },
    exportAssetTypes(ctx, data) {
      return useJwt.exportAssetTypes(data).then(response => response);
    },
  },
};
